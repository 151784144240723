import {Injectable} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext, Store} from "@ngxs/store";
import {ITaskManagementStateModel} from "./models/task-management-state.model";
import {LoadingState} from "../../../../state/loading-state.enum";
import {EMPTY, tap, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {StatusService} from "../../../../api-services/legacy-milestone-api/status/status.service";
import {
  ResourceHierarchy
} from "../../../../api-services/legacy-milestone-api/milestone/models/resource-hierarchy.model";
import {TaskService} from "../../../../api-services/legacy-milestone-api/task/task.service";
import {Levels} from "../../../../api-services/legacy-milestone-api/milestone/models/levels.enum";
import {Stages} from "../../../../api-services/legacy-milestone-api/milestone/models/stages.enum";
import {
  IStatusOverviewModel,
  ITaskOverviewModel,
} from "../../../../api-services/legacy-milestone-api/status/models/status-overview.model";
import {GetStatusesForResources} from "../../../../state/status/status.state";
import {
  IUpdateResourceTaskModel
} from "../../../../api-services/legacy-milestone-api/task/models/update-resource-task.model";
import {WorkflowService} from "../../../../api-services/legacy-milestone-api/workflow/workflow.service";

export class LoadOverviewForHierarchy {
  static readonly type = '[TaskManagement] Load overview for ticket';

  constructor(public hierarchy: ResourceHierarchy, public force: boolean = false) {
  }
}

export class AddWorkflowsToResource {
  static readonly type = '[TaskManagement] Add workflows to resource';

  constructor(public workflowIds: number[], public stage: Stages, public resourceHierarchy: ResourceHierarchy) {
  }
}

export class CompleteOrFailTask {
  static readonly type = '[TaskManagement] Complete or fail task';

  constructor(public taskId: string, public hierarchy: ResourceHierarchy, public wasFailed: boolean) {
  }
}

export class ToggleRowVisibility {
  static readonly type = '[TaskManagement] Toggle row visibility';

  constructor(public externalResourceId: number) {
  }
}

export class ChangeSelectedStage {
  static readonly type = '[TaskManagement] Change selected stage';

  constructor(public newStage: Stages, public externalResourceId: number) {
  }
}

export class UpdateResourceTask {
  static readonly type = '[TaskManagement] Update resource task';

  constructor(public hierarchy: ResourceHierarchy, public taskId: string, public task: IUpdateResourceTaskModel) {
  }
}

export class CollapseAllAccordions {
  static readonly type = '[TaskManagement] Collapse all accordions';
}

export class ToggleDocked {
  static readonly type = '[TaskManagement] Toggle docked';
}

@State<ITaskManagementStateModel>({
  name: 'taskManagement',
  defaults: {
    state: LoadingState.loading,
    hierarchy: null,
    docked: false,
  }
})
@Injectable()
export class TaskManagementState {

  constructor(private statusService: StatusService, private taskService: TaskService, private store: Store, private workflowService: WorkflowService) {
  }

  static getIdOfActiveTask(externalResourceId: number) {
    return createSelector([TaskManagementState], (state: ITaskManagementStateModel) => {
      const overview = state.data?.find((overviewResult) => overviewResult.externalResourceId === externalResourceId);

      if (overview == null) return null;

      return this.getActiveTaskForOverview(overview)?.id!;
    });
  }

  @Selector()
  static getConfirmFinancialStatus(state: ITaskManagementStateModel) {
      if (!state?.data) return false;

      const taskDetail = state.data.find((status) =>
          status.tasks?.some((task) => task.name === "Confirm Financials")
      );

      return taskDetail?.tasks?.some(
          (task) => task.name === "Confirm Financials" && !!task.completedOrFailedDate
      ) ?? false;
  }

  
  private static getActiveTaskForOverview(overview: IStatusOverviewModel): ITaskOverviewModel | null {
    const stagedTasks = overview.tasks.filter((task) => task.stage === Stages.Staged && task.completedOrFailedDate == null);
    const inWorkTasks = overview.tasks.filter((task) => task.stage === Stages.InWork && task.completedOrFailedDate == null);
    const wrapUpTasks = overview.tasks.filter((task) => task.stage === Stages.WrapUp && task.completedOrFailedDate == null);

    if (stagedTasks.length > 0) {
      return stagedTasks.reverse().pop()!;
    }
    if (inWorkTasks.length > 0) {
      return inWorkTasks.reverse().pop()!;
    }
    if (wrapUpTasks.length > 0) {
      return wrapUpTasks.reverse().pop()!;
    }

    return null;
  }

  @Action(LoadOverviewForHierarchy)
  loadOverviewForTicket(ctx: StateContext<ITaskManagementStateModel>, action: LoadOverviewForHierarchy) {
    const currentState = ctx.getState();

    // Don't load if already loaded and hierarchy is the same
    if (!action.force && currentState.state === LoadingState.loaded && currentState.hierarchy?.contractId === action.hierarchy.contractId && currentState.hierarchy?.ticketId === action.hierarchy.ticketId && currentState.hierarchy?.purchaseOrderId === action.hierarchy.purchaseOrderId) {
      return;
    }

    ctx.patchState({
      state: LoadingState.loading,
      data: null,
      hierarchy: {
        contractId: action.hierarchy.contractId,
        ticketId: action.hierarchy.ticketId,
        purchaseOrderId: action.hierarchy.purchaseOrderId,
      }
    });

    return this.statusService.getOverview(action.hierarchy).pipe(
      tap((overview) => {
        ctx.patchState({
          state: LoadingState.loaded,
          data: this.addUIFlagsToOverviewResult(overview),
        });
      }),
      catchError(() => {
        ctx.patchState({
          state: LoadingState.error,
        });

        return EMPTY;
      }),
    );
  }

  @Action(CollapseAllAccordions)
  collapseAllAccordions(ctx: StateContext<ITaskManagementStateModel>) {
    const currentState = ctx.getState();

    let newData = currentState.data?.map((overview) => {
      return {
        ...overview,
        open: false,
      };
    });

    ctx.patchState({
      data: newData,
    });
  }

  @Action(AddWorkflowsToResource)
  associateResourceWithMilestone(ctx: StateContext<ITaskManagementStateModel>, action: AddWorkflowsToResource) {
    ctx.patchState({
      state: LoadingState.loading,
    });

    return this.workflowService.associateWorkflowsWithResource(action.stage, action.resourceHierarchy, action.workflowIds).pipe(
      tap((newOverview) => {
        this.recalculateStatusForEntireHierarchy(action.resourceHierarchy);

        ctx.patchState({
          state: LoadingState.loaded,
          data: this.addUIFlagsToOverviewResult(newOverview),
        });
      }),
      catchError(() => {
        ctx.patchState({
          state: LoadingState.error,
        });

        return EMPTY;
      }),
    );
  }

  @Action(CompleteOrFailTask)
  completeOrFailTask(ctx: StateContext<ITaskManagementStateModel>, action: CompleteOrFailTask) {
    const currentState = ctx.getState();

    ctx.patchState({
      state: LoadingState.loading,
    });

    if (action.wasFailed) {
      return this.taskService.fail(action.taskId, action.hierarchy).pipe(
        tap((res) => {
          if (this.getLevelForHierarchy(action.hierarchy) === Levels.PurchaseOrder) {
            this.recalculateStatusForEntireHierarchy({
              ...currentState.hierarchy!,
              purchaseOrderId: action.hierarchy.purchaseOrderId,
            });
          } else {
            this.recalculateStatusForEntireHierarchy(currentState.hierarchy!);
          }

          ctx.patchState({
            state: LoadingState.loaded,
          });

          this.updateTaskCompletionStateFromTaskId(ctx, action.taskId, true, res.completedOrFailedBy);
        }),
        catchError(() => {
          ctx.patchState({
            state: LoadingState.error,
          });

          return EMPTY;
        }),
      );
    }

    return this.taskService.complete(action.taskId, action.hierarchy).pipe(
      tap((res) => {
        if (this.getLevelForHierarchy(action.hierarchy) === Levels.PurchaseOrder) {
          this.recalculateStatusForEntireHierarchy({
            ...currentState.hierarchy!,
            purchaseOrderId: action.hierarchy.purchaseOrderId,
          });
        } else {
          this.recalculateStatusForEntireHierarchy(currentState.hierarchy!);
        }

        ctx.patchState({
          state: LoadingState.loaded,
        });

        this.updateTaskCompletionStateFromTaskId(ctx, action.taskId, false, res.completedOrFailedBy);
      }),
      catchError(() => {
        ctx.patchState({
          state: LoadingState.error,
        });

        return EMPTY;
      }),
    );
  }

  @Action(ToggleRowVisibility)
  toggleRowVisibility(ctx: StateContext<ITaskManagementStateModel>, action: ToggleRowVisibility) {
    const currentState = ctx.getState();

    let newData = currentState.data?.map((overview) => {
      if (overview.externalResourceId === action.externalResourceId) {
        return {
          ...overview,
          open: !overview.open,
        };
      }
      return overview;
    });

    ctx.patchState({
      data: newData,
    });
  }

  @Action(ChangeSelectedStage)
  changeSelectedStage(ctx: StateContext<ITaskManagementStateModel>, action: ChangeSelectedStage) {
    const currentState = ctx.getState();

    let newData = currentState.data?.map((overview) => {
      if (overview.externalResourceId === action.externalResourceId) {
        return {
          ...overview,
          stage: action.newStage,
        };
      }
      return overview;
    });

    ctx.patchState({
      data: newData,
    });
  }

  @Action(UpdateResourceTask)
  updateResourceTask(ctx: StateContext<ITaskManagementStateModel>, action: UpdateResourceTask) {
    const currentState = ctx.getState();

    ctx.patchState({
      state: LoadingState.loading,
    });

    return this.taskService.updateResourceTask(action.taskId, action.task).pipe(
      tap(() => {
        let newData = currentState.data?.map((overview) => {
          const newOverview: IStatusOverviewModel = {
            ...overview,
            tasks: overview.tasks.map((task) => {
              if (task.id === action.taskId) {
                return {
                  ...task,
                  completedOrFailedDate: action.task.completedOrFailedDate ?? null,
                  wasFailed: action.task.completedOrFailedDate == null ? null : task.wasFailed,
                  assignedToUserId: action.task.assignedToUserId ?? null,
                  assignedToTeamId: action.task.assignedToTeamId ?? null,
                  allowVendorCompletion: action.task.allowVendorCompletion ?? false,
                  dueDate: action.task.dueDate ?? null,
                };
              }
              return task;
            }),
          };

          return newOverview;
        });

        this.recalculateStatusForEntireHierarchy(action.hierarchy);

        ctx.patchState({
          state: LoadingState.loaded,
          data: newData,
        });
      }),
      catchError((err) => {
        ctx.patchState({
          state: LoadingState.error,
        });

        return throwError(() => err);
      }),
    );
  }

  @Action(ToggleDocked)
  toggleDocked(ctx: StateContext<ITaskManagementStateModel>) {
    const currentState = ctx.getState();

    ctx.patchState({
      docked: !currentState.docked,
    });
  }

  private updateTaskCompletionStateFromTaskId(ctx: StateContext<ITaskManagementStateModel>, taskId: string, wasFailed: boolean, completedOrFailedBy: string | null) {
    const currentState = ctx.getState();

    let newData = currentState.data?.map((overview) => {
      // Check to see if the stage changed after the update. If it does, the active stage should be the new one.
      let stageToSet: Stages;
      const stagePriorToUpdate = TaskManagementState.getActiveTaskForOverview(overview)?.stage;
      const newOverview: IStatusOverviewModel = {
        ...overview,
        tasks: overview.tasks.map((task) => {
          if (task.id === taskId) {
            return {
              ...task,
              wasFailed: wasFailed,
              completedBy: completedOrFailedBy,
              completedOrFailedDate: new Date(),
            };
          }
          return task;
        }),
      };
      const stageAfterUpdate = TaskManagementState.getActiveTaskForOverview(newOverview)?.stage;

      // Determine if the user was on the "active" stage before the update. If so, the active stage should be the new one. If not, the user shouldn't be interrupted.
      if (stagePriorToUpdate !== stageAfterUpdate && stagePriorToUpdate == overview.stage && stageAfterUpdate != null && stagePriorToUpdate != null) {
        stageToSet = stageAfterUpdate;
      } else {
        stageToSet = overview.stage;
      }

      return {
        ...newOverview,
        stage: stageToSet,
      };
    });

    ctx.patchState({
      data: newData,
    });
  }

  private addUIFlagsToOverviewResult(overview: IStatusOverviewModel[]): IStatusOverviewModel[] {
    return overview.map((overview) => {
      return {
        ...overview,
        open: false,
        stage: TaskManagementState.getActiveTaskForOverview(overview)?.stage ?? Stages.Staged,
      };
    });
  }

  private recalculateStatusForEntireHierarchy(hierarchy: ResourceHierarchy) {
    const resourcesToRecalculate: ResourceHierarchy[] = [];

    if (hierarchy.purchaseOrderId != null) {
      resourcesToRecalculate.push(hierarchy);
    }

    if (hierarchy.ticketId != null) {
      resourcesToRecalculate.push({
        contractId: hierarchy.contractId,
        ticketId: hierarchy.ticketId,
        purchaseOrderId: null,
      });
    }

    resourcesToRecalculate.push({
      contractId: hierarchy.contractId,
      ticketId: null,
      purchaseOrderId: null,
    })

    this.store.dispatch(new GetStatusesForResources(resourcesToRecalculate));
  }

  private getLevelForHierarchy(hierarchy: ResourceHierarchy): Levels {
    if (hierarchy.purchaseOrderId != null) {
      return Levels.PurchaseOrder;
    }

    if (hierarchy.ticketId != null) {
      return Levels.Ticket;
    }

    return Levels.Contract;
  }

}
